import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

import CheckIcon from '../../assets/icons/CheckIcon';
import { styles } from './styles';

const Checkbox = ({ isChecked = false, isIndeterminate = false, onClick = () => {} }) => {
  return (
    <Box w='0.625vw' onClick={onClick}>
      <Flex sx={styles.customCheckbox(isChecked)}>
        {isChecked ? <CheckIcon /> : null}
        {isIndeterminate && !isChecked ? (
          <Flex width='65%' background='black' height='0.08vw' />
        ) : null}
      </Flex>
    </Box>
  );
};

export default Checkbox;
