/* eslint-disable no-nested-ternary */
export const styles = {
  continueBtn: {
    height: '2vw',
    marginTop: '1.6vh',
    width: '15%',
    alignSelf: 'center',
  },
  backBtn: {
    height: '2.2vw',
    width: '2.2vw',
    minWidth: '2.2vw',
    padding: 0,
    margin: 0,
    borderRadius: '0.313vw',
  },
  switchModeBtn: (isSelected) => ({
    background: isSelected ? '#7368F0' : '#F8F8F8',
    height: '2.2vw',
    minWidth: '8.750vw',
    display: 'flex',
    fontSize: '0.729vw',
    justifyContent: 'space-between',
    padding: '5% 3%',
    position: 'relative',
    color: isSelected ? 'white' : '#625F6E',
    _hover: {
      opacity: isSelected ? 1 : 0.7,
    },
  }),
  dot: {
    borderRadius: '50%',
    background: '#28C76F',
    height: '0.417vw',
    width: '0.417vw',
    position: 'absolute',
    right: '0.625vw',
  },
  elementsBlockTitle: {
    fontSize: '0.938vw',
    color: '#6E6B7B',
    fontWeight: 600,
  },
  elementsScrollBox: {
    overflow: 'auto',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0.208vw',
    },
    '&::-webkit-scrollbar-track': {
      width: '0.208vw',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#6E6B7B',
      borderRadius: '0.208vw',
    },
  },
  propTitleText: {
    fontSize: '0.729vw',
    fontWeight: 600,
  },
  propValueText: {
    color: '#625F6E',
    fontWeight: 600,
    fontSize: '0.729vw',
    marginLeft: '0.313vw',
  },
  divider: {
    background: '#ECECEC',
    width: '93%',
    height: '0.125vh',
    marginTop: '1vh',
    marginBottom: '1vh',
  },
  imageWrapper: {
    border: '0.052vw solid #7368F0',
    borderRadius: '0.313vw',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  elementRectangleWrapper: {
    position: 'absolute',
  },
  lineWrapper: {
    position: 'absolute',
  },
  elementRectangle: {
    // border: type?.includes('Door')
    //   ? '0.02vw solid #7368F0'
    //   : type.includes('Receptacle')
    //   ? '0.02vw solid #28C76F'
    //   : '0.02vw solid #0000FF',
    width: '100%',
    height: '100%',
  },
  elementText: {
    fontSize: '0.08vw',
    color: 'white',
    // background: type?.includes('Door')
    //   ? '#7368F0'
    //   : type.includes('Receptacle')
    //   ? '#28C76F'
    //   : '#0000FF',
    position: 'absolute',
    display: 'flex',
    bottom: '98%',
    flexDirection: 'column',
    paddingLeft: '0.02vw',
    zIndex: 2,
  },
  controlBtn: {
    border: '0.052vw solid transparent',
    height: '1.6vw',
    borderRadius: '0.313vw',
    width: '1.6vw',
    minWidth: '1.6vw',
    maxWidth: '1.6vw',
    background: '#ECECEC',
    display: 'flex',
    spacing: 0,
    margin: 0,
    _hover: {
      opacity: 0.7,
    },
  },
  sortMenu: {},
  sortMenuBtn: {
    borderRadius: '0.313vw',
    background: '#ECECEC',
    height: '1.6vw',
    fontSize: '0.9vw',
    width: '17vw',
    paddingRight: '1vw',
    paddingLeft: '0.1vw',
  },
  sortMenuList: {
    minWidth: '6.250vw',
    border: '0.052vw #6E6B7B solid',
    paddingY: '0.3vw',
    borderRadius: '0.3vw',
    overflow: 'hidden',
  },
  sortMenuItem: {
    fontWeight: 600,
    fontSize: '0.9vw',
    maxHeight: '2vw',
    minWidth: '17vw',
    fontFamily: 'Montserrat',
  },
};
