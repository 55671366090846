import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import WebViewer from '@pdftron/webviewer';
import React from 'react';
import CsvDownloadButton from 'react-json-to-csv';

import LeftArrowIcon from '../../../assets/icons/LeftArrowIcon';
import Card from '../../../components/card';
import { ElementsTree } from '../../../components/elements-tree/elementsTree';
import { styles } from './styles';

export const ViewModeBlock = ({
  setCurrentStep,
  elementsQueryResponse,
  sheetInfo,
  image,
  setCurrentFile,
  currentFile,
  setElementsQueryResponse,
}) => {
  const [sortingMode, setSortingMode] = React.useState('name');
  const imgRef = React.useRef(null);
  const blueprintRef = React.useRef(null);
  const viewer = React.useRef(null);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const handleMoveBack = () => {
    setCurrentStep('uploading');
    setElementsQueryResponse([]);
    setCurrentFile([]);
  };

  React.useEffect(() => {
    WebViewer(
      {
        path: '/lib',
        initialDoc: currentFile[0]?.preview,
      },
      viewer.current,
    ).then((instance) => {
      instance.UI.disableElements(['toolsHeader']);
      instance.UI.setHeaderItems((header) => {
        const items = header.getItems().slice(3, -5);
        items.push({
          type: 'toolButton',
          toolName: 'MarqueeZoomTool',
          img: 'https://cdn4.iconfinder.com/data/icons/edition-1/128/Edition_zoom_in_selection-512.png',
        });
        header.update(items);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function for DOM drilling through PDF lib wrapper
  const selectPDFContainer = () => {
    const embed1 = window?.document.querySelector('.webviewer > iframe');
    const embed2 = embed1?.contentDocument;
    const embed3 = embed2?.querySelector('#app');
    const embed4 = embed3?.querySelector('#virtualListContainer');
    const embed5 = embed4?.querySelector('#pageWidgetContainer1');
    blueprintRef.current = embed5;
    return embed5;
  };

  // function for creating elements for blueprint according to checkboxes
  const renderBlueprintElements = () => {
    // checking for selected elements
    const nodesToShow = selectedItems?.filter(
      (item) => item.type === 'element' && item.state === 'checked',
    );

    // checking for disabled elements
    const nodesToRemove = selectedItems?.filter(
      (item) => item.type === 'element' && item.state !== 'checked',
    );

    let elementLeftMargin;
    let elementTopMargin;
    let elementHeight;
    let elementWidth;
    let lineLeftMargin;
    let lineTopMargin;

    const elementsList = window?.document.createDocumentFragment();

    nodesToShow?.map((element) => {
      if (element && !element?.id?.includes('line')) {
        const rectangleProps = element?.properties;
        elementHeight =
          (rectangleProps.y1 - rectangleProps.y0) /
          (Number.parseInt(imgRef?.current?.naturalHeight, 10) / 100);
        elementWidth =
          (rectangleProps.x1 - rectangleProps.x0) /
          (Number.parseInt(imgRef?.current?.naturalWidth, 10) / 100);
        elementLeftMargin =
          rectangleProps.x0 / (Number.parseInt(imgRef?.current?.naturalWidth, 10) / 100);
        elementTopMargin =
          rectangleProps.y0 / (Number.parseInt(imgRef?.current?.naturalHeight, 10) / 100);
      }

      if (element && element?.id?.includes('line')) {
        const lineProps = element?.properties;
        lineLeftMargin = lineProps.x / (Number.parseInt(imgRef?.current?.naturalWidth, 10) / 100);
        lineTopMargin = lineProps.y / (Number.parseInt(imgRef?.current?.naturalHeight, 10) / 100);
      }

      // checking for element existence to prevent duplicate creating
      if (
        !selectPDFContainer()?.querySelector(`[name="${element.id}"]`) &&
        !element?.id?.includes('line')
      ) {
        const el = window?.document?.createElement('div');
        el.style.border = '0.2vw solid';
        el.style.borderColor = element?.elementColor;
        el.style.left = `${elementLeftMargin}%`;
        el.style.top = `${elementTopMargin}%`;
        el.style.height = `${elementHeight}%`;
        el.style.width = `${elementWidth}%`;
        el.style.borderRadius = '0.208vw';
        el.style.position = 'absolute';
        el.style.zIndex = 10;
        el.style.display = 'flex';
        el.setAttribute('name', element.id);

        const textWrapper = window?.document?.createElement('div');
        textWrapper.style.overflow = 'hidden';
        textWrapper.style.position = 'absolute';
        textWrapper.style.background = element?.elementColor;
        textWrapper.style.top = '105%';
        textWrapper.style.display = 'flex';
        textWrapper.style.flexDirection = 'column';

        el?.appendChild(textWrapper);

        Object.keys(element?.properties).map((propName) => {
          if (
            !propName.includes('x0') &&
            !propName.includes('x1') &&
            !propName.includes('y0') &&
            !propName.includes('y1')
          ) {
            const propText = window?.document?.createElement('pre');
            // eslint-disable-next-line no-unsafe-optional-chaining
            propText.style.width = `${(propName + element?.properties[propName]).length}ch`;
            propText.style.margin = 0;
            propText.style.fontSize = `10px`;
            propText.style.color = 'white';
            textWrapper?.appendChild(propText);
            propText?.append(`${propName}: ${element?.properties[propName]}; `);
          }
          return null;
        });

        elementsList.appendChild(el);
      }

      if (
        !selectPDFContainer()?.querySelector(`[name="${element.id}"]`) &&
        element?.id?.includes('line')
      ) {
        const el = window?.document?.createElement('div');
        el.style.background = 'brown';
        el.style.left = element?.properties?.is_vertical ? `${lineLeftMargin}%` : 0;
        el.style.top = element?.properties?.is_vertical ? 0 : `${lineTopMargin}%`;
        el.style.height = element?.properties?.is_vertical ? '100%' : '0.07%';
        el.style.width = element?.properties?.is_vertical ? '0.07%' : '100%';
        el.style.position = 'absolute';
        el.style.zIndex = 10;
        el.setAttribute('name', element.id);

        elementsList.appendChild(el);
      }
      return null;
    });
    nodesToRemove.map((element) => {
      selectPDFContainer()?.querySelector(`[name="${element.id}"]`)?.remove();
      return null;
    });
    return elementsList;
  };

  React.useLayoutEffect(() => {
    selectPDFContainer()?.appendChild(renderBlueprintElements());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.document, selectedItems]);

  const processElementName = () => {
    const sheetNameRegex = /LEVEL [0-9]+/gm;
    let processedSheetName;
    if (sheetInfo?.drawing_name) {
      processedSheetName = sheetInfo?.drawing_name?.message.match(sheetNameRegex);
      if (processedSheetName) {
        processedSheetName = processedSheetName[0]?.replace(' ', '');
      }
    }
    return processedSheetName ?? '';
  };

  const processDataForCSV = () => {
    const result = [];
    // eslint-disable-next-line no-unused-vars
    const elementProperties = elementsQueryResponse?.properties;
    elementsQueryResponse?.map((element) => {
      if (element?.type === 'element' && !element?.id.includes('line')) {
        const processedElement = {};
        processedElement.element = element?.name;
        Object.assign(processedElement, element?.properties);
        result.push(processedElement);
      }
      return null;
    });
    return result;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElementsTree = React.useCallback(
    <ElementsTree setSelectedItems={setSelectedItems} elements={elementsQueryResponse} />,
    [],
  );

  const renderSidebar = () => {
    const sidebarData = elementsQueryResponse.filter((i) => i.type !== 'folder');

    sidebarData?.map((element) => {
      if (element.name.includes('line')) {
        Object.defineProperty(element?.properties, 'prediction', { value: 1.0 });
      }
      return null;
    });

    return sidebarData
      ?.sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        sortingMode === 'name'
          ? a?.name?.localeCompare(b?.name)
          : a?.properties?.prediction < b?.properties?.prediction
          ? 1
          : -1,
      )
      .map((element, idx) => {
        return (
          <Box key={idx}>
            {element.name.includes('line') ? (
              <VStack alignItems='flex-start' spacing='0.3vh' mt='1vh' w='100%'>
                <Flex>
                  <Text sx={styles.propTitleText}>Element name:</Text>
                  <Text sx={styles.propValueText}>
                    {element?.name}x{processElementName()}
                  </Text>
                </Flex>
                <Flex>
                  <Text sx={styles.propTitleText}>Prediction accuracy:</Text>
                  <Text sx={styles.propValueText}>100%</Text>
                </Flex>
                <HStack spacing='0.35vw'>
                  <Text sx={styles.propTitleText}>x:</Text>
                  <Text sx={styles.propValueText}>{element?.properties?.x}</Text>
                  <Text sx={styles.propTitleText}>y:</Text>
                  <Text sx={styles.propValueText}>{element?.properties?.y}</Text>
                </HStack>
              </VStack>
            ) : (
              <VStack alignItems='flex-start' spacing='0.3vh' mt='1vh' w='100%'>
                <Flex>
                  <Text sx={styles.propTitleText}>Element name:</Text>
                  <Text sx={styles.propValueText}>
                    {element?.name}x{processElementName()}
                  </Text>
                </Flex>
                <Flex>
                  <Text sx={styles.propTitleText}>Prediction accuracy:</Text>
                  <Text sx={styles.propValueText}>
                    {`${Number.parseFloat(element?.properties?.prediction, 10).toFixed(2) * 100}%`}
                  </Text>
                </Flex>
                <HStack spacing='0.35vw'>
                  <Text sx={styles.propTitleText}>x0:</Text>
                  <Text sx={styles.propValueText}>{element?.properties?.x0}</Text>
                  <Text sx={styles.propTitleText}>x1:</Text>
                  <Text sx={styles.propValueText}>{element?.properties?.x1}</Text>
                  <Text sx={styles.propTitleText}>y0:</Text>
                  <Text sx={styles.propValueText}>{element?.properties?.y0}</Text>
                  <Text sx={styles.propTitleText}>y1:</Text>
                  <Text sx={styles.propValueText}>{element?.properties?.y1}</Text>
                </HStack>
                <Flex>
                  <Text sx={styles.propTitleText}>Center:</Text>
                  <Text
                    sx={styles.propValueText}
                  >{`${element?.properties?.center[0]} | ${element?.properties?.center[1]}`}</Text>
                </Flex>
                <Flex>
                  <Text sx={styles.propTitleText}>Location:</Text>
                  <Text sx={styles.propValueText}>{`${element?.properties?.name}`}</Text>
                </Flex>
              </VStack>
            )}
            <Box sx={styles.divider} />
          </Box>
        );
      });
  };

  return (
    <Box>
      <>
        <Card
          wrapperProps={{
            width: '100%',
            height: '3vw',
            paddingX: '0.729vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <IconButton sx={styles.backBtn} onClick={handleMoveBack}>
            <LeftArrowIcon />
          </IconButton>
          <Flex>
            <CsvDownloadButton
              data={processDataForCSV()}
              filename='Element List.csv'
              style={{
                background: '#E9EBED',
                borderRadius: '0.25vw',
                display: 'inline-block',
                cursor: 'pointer',
                color: '#000',
                fontSize: '0.7vw',
                fontWeight: 'bold',
                padding: '0.3vw 0.5vw',
                textDecoration: 'none',
                marginRight: '1vw',
              }}
            >
              Extract CSV
            </CsvDownloadButton>
            <Menu sx={styles.sortMenu}>
              <MenuButton
                as={Button}
                sx={styles.sortMenuBtn}
                rightIcon={<ChevronDownIcon fontSize='1.042vw' />}
              >
                Sort by: {sortingMode === 'name' ? 'alphabetical order' : 'prediction accuracy'}
              </MenuButton>
              <MenuList sx={styles.sortMenuList}>
                <MenuItem sx={styles.sortMenuItem} onClick={() => setSortingMode('name')}>
                  Alphabetical order
                </MenuItem>
                <MenuItem sx={styles.sortMenuItem} onClick={() => setSortingMode('accuracy')}>
                  Prediction accuracy
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Card>
        <Flex mt='1.6vh'>
          <Card
            wrapperProps={{
              width: '14%',
              height: '81vh',
              flexDirection: 'column',
              padding: '0.938vw 0.5vw 0.938vw 0.938vw',
              display: 'flex',
              position: 'relative',
            }}
          >
            {renderElementsTree}
          </Card>
          <Card
            wrapperProps={{
              width: '60%',
              height: '81vh',
              padding: '1.667vh 0.938vw',
              flexDirection: 'column',
              marginLeft: '1vw',
            }}
          >
            <Box sx={styles.imageWrapper}>
              <div
                className='webviewer'
                ref={viewer}
                style={{ height: '100%', width: '100%', position: 'relative' }}
              />
            </Box>
          </Card>
          <Card
            wrapperProps={{
              width: '25vw',
              height: '81vh',
              padding: '2vh 1.1vw 2vh 1.563vw',
              flexDirection: 'column',
              marginLeft: '1vw',
            }}
          >
            <Flex flexDirection='column'>
              <Text sx={styles.elementsBlockTitle} mb='0.4vh'>
                Sheet name:
              </Text>
              <Text sx={styles.propTitleText} mb='0.4vh'>
                {sheetInfo?.drawing_name?.message}
              </Text>
            </Flex>
            <Flex flexDirection='column'>
              <Text sx={styles.elementsBlockTitle} mb='0.4vh'>
                Sheet number:
              </Text>
              <Text sx={styles.propTitleText} mb='0.4vh'>
                {sheetInfo?.drawing_number?.message}
              </Text>
            </Flex>
            <Text sx={styles.elementsBlockTitle}>Elements:</Text>
            <Box sx={styles.elementsScrollBox}>{renderSidebar()}</Box>
          </Card>
        </Flex>
      </>
      <Image ref={imgRef} src={image[0]} visibility='hidden' pos='fixed' />
    </Box>
  );
};
