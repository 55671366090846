/* eslint-disable no-use-before-define */
import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

import Checkbox from './checkbox';
import CheckboxList from './checkbox-list';
import { styles } from './styles';

export const ElementsTree = ({ setSelectedItems, elements }) => {
  const defaultItemStates = elements.map((element) => ({
    id: element?.id,
    state: 'unchecked',
    type: element?.type,
    properties: element?.properties,
    parentId: element?.parentId,
    elementColor: element?.elementColor,
  }));

  const [itemStates, setItemStates] = React.useState(defaultItemStates);
  const [generalCheckboxState, setGeneralCheckboxState] = React.useState(false);

  React.useEffect(() => {
    const generalFolders = itemStates?.filter(
      (item) => item.type === 'folder' && item?.parentId === null,
    );
    generalFolders.map((folder) => {
      if (folder?.state === 'unchecked') {
        setGeneralCheckboxState(false);
      }
      if (generalFolders.length === generalFolders.filter((i) => i.state === 'checked').length) {
        setGeneralCheckboxState(true);
      }
      return null;
    });
    setSelectedItems(itemStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemStates]);

  const updateItemStates = (oldState, items, clickedIds) => {
    const newState = oldState.map((i) => ({ ...i }));
    // getters
    const getItemState = (id) => {
      return newState.find((i) => i.id === id).state;
    };
    // setters
    const updateParent = (id) => {
      const item = items.find((i) => i.id === id);
      const parent = items.find((i) => i.id === item.parentId);
      if (!parent) return;
      const childIds = items.filter((i) => i.parentId === parent.id).map((i) => i.id);
      const childStates = childIds.map((childId) => getItemState(childId));
      if (childStates.length === childStates.filter((s) => s === 'checked').length) {
        newState.find((i) => i.id === parent.id).state = 'checked';
      } else if (childStates.length === childStates.filter((s) => s === 'unchecked').length) {
        newState.find((i) => i.id === parent.id).state = 'unchecked';
      } else {
        newState.find((i) => i.id === parent.id).state = 'indeterminate';
      }
      updateParent(parent.id);
    };
    const setUnchecked = (id) => {
      newState.find((i) => i.id === id).state = 'unchecked';
      items
        .filter((i) => i.parentId === id)
        .map((i) => i.id)
        .forEach((childId) => setUnchecked(childId));
      updateParent(id);
    };
    const setChecked = (id) => {
      newState.find((i) => i.id === id).state = 'checked';
      items
        .filter((i) => i.parentId === id)
        .map((i) => i.id)
        .forEach((childId) => setChecked(childId));
      updateParent(id);
    };
    // actual logic
    if (typeof clickedIds !== 'object') {
      const itemState = getItemState(clickedIds);
      if (itemState === 'checked') {
        setUnchecked(clickedIds);
      } else {
        setChecked(clickedIds);
      }
    } else {
      clickedIds?.map((clickedId) => {
        const itemState = getItemState(clickedId);
        if (itemState === 'checked') {
          setUnchecked(clickedId);
        } else {
          setChecked(clickedId);
        }
        return null;
      });
    }
    setSelectedItems(newState);
    return newState;
  };
  const getStateForId = React.useCallback(
    (id) => {
      return itemStates.find((i) => i.id === id).state;
    },
    [itemStates],
  );
  const clickHandler = React.useCallback(
    (ids) => setItemStates(updateItemStates(itemStates, elements, ids)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemStates],
  );

  const handleSwitchAll = () => {
    const folderIds = [];
    const folders = itemStates?.filter(
      (element) => element?.type === 'folder' && element?.parentId === null,
    );
    if (!generalCheckboxState) {
      folders
        .filter((i) => i.state === 'unchecked' || i.state === 'indeterminate')
        .map((folder) => {
          folderIds.push(folder.id);
          return null;
        });
    }
    if (generalCheckboxState) {
      folders
        .filter((i) => i.state === 'checked')
        .map((folder) => {
          folderIds.push(folder.id);
          return null;
        });
    }
    clickHandler(folderIds);
  };

  return (
    <Flex sx={styles.itemsTreeWrapper}>
      <Flex flexDirection='column' mr='0.25vw'>
        <Flex alignItems='center' justifyContent='space-between' mb='1.354vw'>
          <Text sx={styles.sheetItemsText}>Sheet items</Text>
          <Checkbox
            onClick={() => {
              handleSwitchAll();
            }}
            isChecked={generalCheckboxState}
          />
        </Flex>
        <CheckboxList items={elements} onClick={clickHandler} getStateForId={getStateForId} />
      </Flex>
    </Flex>
  );
};

export const SHEETDATA = {
  sheetName: 'A151',
  dimensions: {
    width: 1066.8,
    height: 762,
  },
  sheetProperties: [
    {
      propName: 'Plug',
      propValue: '40',
    },
    {
      propName: 'Location',
      propValue: '38',
    },
    {
      propName: 'Power',
      propValue: '250w',
    },
    {
      propName: 'Sheet',
      propValue: 'A151',
    },
  ],
  elements: [
    { id: 'Receptacles', name: 'Receptacles', parentId: null, type: 'folder' },
    { id: 'Doors', name: 'Doors', parentId: null, type: 'folder' },
    { id: 'Walls', name: 'Walls', parentId: null, type: 'folder' },
    { id: 'Plumbing Fixtures', name: 'Plumbing Fixtures', parentId: null, type: 'folder' },
    { id: 'Areas', name: 'Area', parentId: null, type: 'folder' },
    { id: 'Room Names', name: 'Room Name', parentId: null, type: 'folder' },
    { id: 'Room Numbers', name: 'Room Number', parentId: null, type: 'folder' },
    { id: 'Wall Tags', name: 'Wall Tag', parentId: null, type: 'folder' },
    { id: 'Door Tags', name: 'Door Tag', parentId: null, type: 'folder' },
    { id: 'Window Elevations', name: 'Window Elevation', parentId: null, type: 'folder' },
    { id: 'Grids', name: 'Grids', parentId: null, type: 'folder' },
    { id: 'Texts', name: 'Text', parentId: null, type: 'folder' },

    {
      id: 'Door D001',
      name: 'Door D001',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '207.99552',
          left: '358.95279999999997',
        },
        size: {
          height: '15',
          width: '15',
        },
        info: [],
      },
    },
    {
      id: 'Door D002',
      name: 'Door D002',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '207.82788',
          left: '429.41239999999993',
        },
        size: {
          height: '15',
          width: '15',
        },
        info: [],
      },
    },
    {
      id: 'Receptacle 11111',
      name: 'Receptacle 11111',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '170',
          left: '250',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'receptacle',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Wall 1',
      name: 'Wall 1',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '100',
          left: '100',
        },
        size: {
          height: '20',
          width: '1',
        },
        type: 'wall',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Plumbing Fixture 1',
      name: 'Plumbing Fixture 1',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '15',
          left: '270',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'plumbingFixture',
        info: [
          {
            propName: 'Plumbing Fixtures',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Area 1',
      name: 'Area 1',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '45',
          left: '130',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'area',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Room Name 1',
      name: 'Room Name 1',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '210',
          left: '100',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'roomName',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Room Number 1',
      name: 'Room Number 1',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '90',
          left: '90',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'roomNumber',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Wall Tag 1',
      name: 'Wall Tag 1',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '350',
          left: '210',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'wallTag',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Door Tag 1',
      name: 'Door Tag 1',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '90',
          left: '260',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'doorTag',
        info: [
          {
            propName: 'Door Tag',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Window Elevation 1',
      name: 'Window Elevation 1',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '10',
          left: '156',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'windowElevation',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Grid 1',
      name: 'Grid 1',
      parentId: 'Grids',
      type: 'element',
      properties: {
        coords: {
          top: '39',
          left: '33.9',
        },
        size: {
          height: '15',
          width: '1',
        },
        type: 'grid',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Text 1',
      name: 'Text 1',
      parentId: 'Texts',
      type: 'element',
      properties: {
        coords: {
          top: '78',
          left: '25.9',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'text',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
  ],
};
