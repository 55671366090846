export const styles = {
  fileNameText: {
    marginRight: '0.313vw',
    fontSize: '0.833vw',
    fontWeight: 600,
    lineHeight: '140%',
  },
  fileSizeText: {
    color: 'gray.500',
    fontSize: '0.833vw',
    fontWeight: 600,
    lineHeight: '140%',
  },
  progressBar: {
    width: '95%',
    height: '0.417vw',
    marginLeft: '1.354vw',
    marginTop: '0.365vw',
    borderRadius: '0.521vw',
  },
  progressValueText: {
    marginLeft: '0.313vw',
    fontSize: '0.625vw',
    fontWeight: 600,
    lineHeight: '186%',
    color: '#7368F0',
  },
};
