import { Box, Button } from '@chakra-ui/react';

import LogoIcon from '../../assets/icons/LogoIcon';
import { styles } from './styles';

const Header = () => {
  return (
    <Box sx={styles.headerWrapper}>
      <LogoIcon />
      <Button>
        <a href='https://panel.drawer.ai/projects'>Back to site</a>
      </Button>
    </Box>
  );
};

export default Header;
