import { Box } from '@chakra-ui/react';
import React from 'react';

import { useFileUpload } from '../../hooks';
import { LoadingModeBlock } from './components/loading-mode-block';
import { ViewModeBlock } from './components/view-mode-block';

const MainPage = () => {
  const { selectedFiles, getRootProps, getInputProps } = useFileUpload();
  const [currentStep, setCurrentStep] = React.useState('uploading');
  const [currentFile, setCurrentFile] = React.useState([]);
  const [sheetInfo, setSheetInfo] = React.useState(null);

  const [loadingStatus, setLoadingStatus] = React.useState('loading');
  const [elementsQueryResponse, setElementsQueryResponse] = React.useState([]);
  const [image, setImage] = React.useState();

  React.useEffect(() => {
    setCurrentFile(selectedFiles);
  }, [selectedFiles]);

  const handleProceedToView = () => {
    setCurrentStep('view');
  };

  return (
    <Box padding='1.667vh 0.938vw'>
      {currentStep === 'uploading' ? (
        <LoadingModeBlock
          setElementsQueryResponse={setElementsQueryResponse}
          elementsQueryResponse={elementsQueryResponse}
          setSheetInfo={setSheetInfo}
          currentFile={currentFile}
          setCurrentFile={setCurrentFile}
          setLoadingStatus={setLoadingStatus}
          handleProceedToView={handleProceedToView}
          loadingStatus={loadingStatus}
          getInputProps={getInputProps}
          getRootProps={getRootProps}
          setImage={setImage}
          image={image}
        />
      ) : (
        <ViewModeBlock
          setElementsQueryResponse={setElementsQueryResponse}
          elementsQueryResponse={elementsQueryResponse}
          setCurrentStep={setCurrentStep}
          setCurrentFile={setCurrentFile}
          currentFile={currentFile}
          sheetInfo={sheetInfo}
          image={image}
        />
      )}
    </Box>
  );
};
export default MainPage;
