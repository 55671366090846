import { Box } from '@chakra-ui/react';

import Header from '../header';
import { styles } from './styles';

const Layout = ({ children }) => {
  return (
    <Box sx={styles.mainContentWrapper}>
      <Header />
      <main>{children}</main>
    </Box>
  );
};

export default Layout;
