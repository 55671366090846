export const styles = {
  accordionBtn: {
    paddingRight: '0.625vw',
    paddingLeft: '0.625vw',
    height: '1.667vw',
  },
  accordionBtnText: {
    fontWeight: 600,
    fontSize: '0.729vw',
    lineHeight: '160%',
  },
  checkboxWrapper: {
    height: '1.667vw',
    paddingLeft: '0.938vw',
    alignItems: 'center',
    width: '100%',
  },
  sheetItemsDivider: {
    width: '11.146vw',
    height: '0.052vw',
    background: '#ECECEC',
    marginY: '0.625vw',
  },
  accordionWrapper: {
    width: '100%',
  },
  accordionTitleText: {
    fontSize: '0.833vw',
    lineHeight: '140%',
    fontWeight: 600,
  },
  elementsAccordionBtn: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    height: '1.042vw',
    padding: 0,
    margin: 0,
  },
  accordionFileWrapper: {
    alignItems: 'center',
    width: '100%',
    padding: 0,
    margin: 0,
    height: '1.667vw',
  },
  sheetItemsText: {
    fontSize: '0.938vw',
    lineHeight: '124%',
    fontWeight: 600,
    color: '#6E6B7B',
  },
  itemsTreeWrapper: {
    flexDirection: 'column',
    overflowY: 'auto',
    height: '85vh',
    '&::-webkit-scrollbar': {
      width: '0.208vw',
    },
    '&::-webkit-scrollbar-track': {
      width: '0.208vw',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#6E6B7B',
      borderRadius: '0.208vw',
    },
  },
  customCheckbox: (isSelected) => ({
    border: isSelected ? 'unset' : '0.08vw solid black',
    background: isSelected ? '#7367f0' : 'default',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10%',
    height: '0.625vw',
    width: '0.625vw',
    cursor: 'pointer',
  }),
};
