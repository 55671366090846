import { Box, Flex, Progress, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import DeleteIcon from '../../assets/icons/DeleteIcon';
import FilePlusIcon from '../../assets/icons/FilePlusIcon';
import Card from '../card';
import { styles } from './styles';

export const FileCard = ({
  file,
  setCurrentFile,
  loadingStatus,
  progressValue,
  setLoadingStatus,
}) => {
  React.useEffect(() => {
    if (progressValue === 100) {
      setLoadingStatus('done');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, progressValue]);

  const handleDeleteFile = () => {
    setCurrentFile([]);
  };

  return (
    <Card
      wrapperProps={{
        width: '100%',
        padding: '0.625vw',
        display: 'flex',
        flexDirection: 'column',
        mt: '1.6vh',
      }}
    >
      <Flex justifyContent='space-between' w='100%'>
        <Flex>
          <FilePlusIcon width='1.042vw' height='1.042vw' />
          <VStack spacing='0.104vw' align='flex-start' ml='0.313vw'>
            <Flex alignItems='center'>
              <Text sx={styles.fileNameText}>{file?.name}</Text>
            </Flex>
            <Text sx={styles.fileSizeText}>{`${(file.size / 1024 ** 2).toFixed(2)} mb`}</Text>
          </VStack>
        </Flex>
        <Box cursor='pointer' h='1.042vw'>
          <DeleteIcon onClick={handleDeleteFile} />
        </Box>
      </Flex>
      <Flex>
        <Progress value={progressValue} sx={styles.progressBar} colorScheme='purple' />
        <Text sx={styles.progressValueText}>
          {loadingStatus !== 'error' ? `${progressValue}%` : 'error'}
        </Text>
      </Flex>
    </Card>
  );
};
