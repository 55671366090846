export const styles = {
  headerWrapper: {
    height: '7.5%',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    paddingX: '1.667vw',
    justifyContent: 'space-between',
  },
  logoText: {
    fontSize: '1.3vw',
    fontWeight: 600,
  },
};
