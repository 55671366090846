import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import React from 'react';

import FileListIcon from '../../assets/icons/FileListIcon';
import FolderLineIcon from '../../assets/icons/FolderLineIcon';
import FolderOpenIcon from '../../assets/icons/FolderOpen';
import Checkbox from './checkbox';
import { styles } from './styles';

const CheckboxList = ({
  items,
  getStateForId,
  idsToRender = [],
  indentLevel = 0,
  onClick = () => {},
}) => {
  if (!idsToRender.length) {
    // eslint-disable-next-line no-param-reassign
    idsToRender = items.filter((i) => !i.parentId).map((i) => i.id);
  }
  const lastParentBlock = items.filter((i) => i.parentId === null);

  const getChildNodes = (parentId) => {
    const nodeItems = items.filter((i) => i.parentId === parentId);
    if (!nodeItems.length) return null;
    return (
      <CheckboxList
        items={items}
        idsToRender={nodeItems.map((i) => i.id)}
        indentLevel={indentLevel + 1}
        onClick={onClick}
        getStateForId={getStateForId}
      />
    );
  };

  return idsToRender.map((id) => {
    const item = items.find((i) => i.id === id);
    const checkboxState = getStateForId(id);
    return (
      <Box sx={styles.accordionWrapper} key={item.id}>
        <Accordion allowMultiple>
          <AccordionItem
            mt={indentLevel > 0 && item.type === 'folder' ? '0.625vw' : 0}
            ml={indentLevel === 1 ? `${0.521 + indentLevel}vw` : `${0.521 + indentLevel / 3}vw`}
          >
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  sx={item.type === 'folder' ? styles.elementsAccordionBtn : null}
                  _hover={{
                    background: 'transparent',
                  }}
                  position='relative'
                >
                  <Flex
                    alignItems='center'
                    sx={item.type === 'folder' ? null : styles.accordionFileWrapper}
                  >
                    {item.type === 'folder' ? <AccordionIcon fontSize='1.042vw' /> : null}
                    {item.type === 'folder' ? (
                      <Flex>
                        {isExpanded ? (
                          <FolderOpenIcon fill='#6E6B7B' />
                        ) : (
                          <FolderLineIcon fill='#6E6B7B' />
                        )}
                      </Flex>
                    ) : (
                      <FileListIcon fill='#6E6B7B' />
                    )}

                    <Text ml='0.313vw' fontSize='0.7vw'>
                      {item.name}
                    </Text>
                    <Box position='absolute' right='0'>
                      <Checkbox
                        onClick={(e) => {
                          // eslint-disable-next-line no-unused-expressions
                          e.preventDefault();
                          onClick(item.id);
                        }}
                        isChecked={checkboxState === 'checked'}
                        isIndeterminate={checkboxState === 'indeterminate'}
                      />
                    </Box>
                  </Flex>
                </AccordionButton>
                <AccordionPanel>{getChildNodes(item.id)}</AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
        {indentLevel === 0 && lastParentBlock.at(-1).id !== item.id ? (
          <Box sx={styles.sheetItemsDivider} />
        ) : null}
      </Box>
    );
  });
};

export default CheckboxList;
