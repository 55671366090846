export const ITEM_COLORS = [
  '#BA2020',
  '#BA4720',
  '#94BA20',
  '#BA6D20',
  '#BA9420',
  '#6DBA20',
  '#47BA20',
  '#20BA20',
  '#20BA47',
  '#20BA6D',
  '#20BA94',
  '#20BABA',
  '#2094BA',
  '#206DBA',
  '#2047BA',
  '#2020BA',
  '#4720BA',
  '#6D20BA',
  '#9420BA',
  '#BA20BA',
  '#BA2094',
  '#BA206D',
  '#BA2047',
  '#BA2020',
  '#681DA7',
  '#AB79D6',
  '#5C1A95',
];
