import { extendTheme } from '@chakra-ui/react';

import { Accordion } from './components/accordion.theme';

const overrides = {
  components: {
    Accordion,
  },
};

export default extendTheme(overrides);
